import TrashIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { FormikErrors } from 'formik'
import { useState } from 'react'
import { useAppSelector } from 'src/hooks/redux'
import { DataCollectorService } from 'src/models/agent'
import { BaseInfo } from '../SignupPage'

interface CollectorFormTwoProps {
  agentInfo: CollectorFormTwoInfo,
  onChange: any,
  errors: FormikErrors<CollectorFormTwoInfo>,
  setAgentInfo: (field: keyof CollectorFormTwoInfo, value: any, shouldValidate?: boolean | undefined) => void
}

export interface CollectorFormTwoInfo extends BaseInfo {
  service_types_ids: DataCollectorService[],
}

const CollectorFormTwo: React.FC<CollectorFormTwoProps> = ({ agentInfo, setAgentInfo, errors }) => {
  const { visa_types, kyc_section_titles } = useAppSelector((state) => state.config);
  const [service, setService] = useState<DataCollectorService>({ title_ids: null, visa_id: null })

  return (
    <Grid container spacing={2} item lg={12} mt={1} alignItems={'center'} justifyContent="center">
      <>
        <Grid item xs={12}>
          <Typography variant='h4' color={'#663399'}>Services Added ({(agentInfo.service_types_ids ?? []).length})</Typography>
        </Grid>
        <Grid item xs={12} height={'400px'} borderRadius={0.6} overflow={'auto'}>
          {(agentInfo.service_types_ids ?? []).length === 0 && <Typography textAlign={'center'}>No service added yet. Add at least one to proceed.</Typography>}
          {(agentInfo.service_types_ids ?? []).map((service_info) => {
            const service = visa_types.find((service_type) => service_type.id === service_info.visa_id);
            const titles = service_info.title_ids?.map((title_id) => {
              return kyc_section_titles.find((section_title) => section_title.id === title_id)?.title;
            })
            return (
              <Box marginTop={0.5}>
                <Card>
                  <CardContent>
                    <Box display={'flex'}>
                      <Box flexGrow={1}>
                        <Typography variant='h5'>{service?.visa_name}</Typography>
                        <Typography variant='subtitle1'>{titles?.join(', ')}</Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => {
                            const remainingServices = agentInfo.service_types_ids.filter((service) => service.visa_id !== service_info.visa_id);
                            setAgentInfo('service_types_ids', remainingServices);
                          }}
                          color="error"
                        >
                          <TrashIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )
          })}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Service Type</InputLabel>
            <Select
              labelId="select-label"
              variant="outlined"
              name="service_type"
              label="Services Offered"
              onChange={(e) => {
                setService((service) => ({ ...service, visa_id: e.target.value as number }))
              }}
              value={service.visa_id ?? ''}
            >
              {visa_types.filter((types) => {
                const res = (agentInfo.service_types_ids ?? []).find((type_ids) => type_ids.visa_id === types.id)
                if (res) {
                  return null;
                }
                return types;
              }).map((tag) =>
                <MenuItem key={tag.id} value={tag.id}>{tag.visa_name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select KYC Sections</InputLabel>
            <Select
              multiple
              labelId="select-label"
              variant="outlined"
              size={(service.title_ids ?? []).length === 0 ? 'medium' : 'small'}
              name='kyc_section_titles'
              onChange={(e) => {
                setService((service) => ({ ...service, title_ids: e.target.value as number[] }))
              }}
              value={service.title_ids ?? []}
              renderValue={(selected) => (
                <div style={{ margin: '2px' }}>
                  {selected.map((item) => {
                    const title = kyc_section_titles.find((section) => section.id === item);
                    return <Chip key={title?.title} label={title?.title} />
                  })}
                </div>
              )}
            >
              {kyc_section_titles.map((title) =>
                <MenuItem value={title.id}>{title.title}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            color='primary'
            fullWidth
            variant='contained'
            onClick={() => {
              if (service.visa_id == null || service.title_ids?.length === 0) return;
              const service_types_ids = [...(agentInfo.service_types_ids ?? [])];
              service_types_ids.push(service);
              setAgentInfo('service_types_ids', service_types_ids);
              setService({ title_ids: null, visa_id: null })
            }}
          >
            {('Save')}
          </Button>
          <Typography fontSize={12} color={'red'}>{errors.service_types_ids as string}</Typography>
        </Grid>
      </>
    </Grid>
  )
}

export default CollectorFormTwo
