import CertificateIcon from '@mui/icons-material/ContentPaste';
import PeopleOutline from '@mui/icons-material/PeopleOutline';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AddJobIcon from '@mui/icons-material/WorkOutline';
import { AgentCategory } from 'src/models/agent';

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  access_roles: AgentCategory[]
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboard',
        access_roles: ['company', 'data-collector', 'individual'],
        icon: DashboardTwoToneIcon,
        link: '/general/dashboard'
      }
    ]
  },
  {
    heading: 'Services',
    items: [
      {
        name: 'Clients',
        access_roles: ['data-collector'],
        icon: PeopleOutline,
        link: '/clients'
      },
      {
        name: 'Application Requests',
        access_roles: ['company', 'individual'],
        icon: CertificateIcon,
        link: '/applications/all'
      },
      {
        name: 'Manage Recruitments',
        access_roles: ['company', 'individual'],
        icon: AddJobIcon,
        items: [
          {
            name: 'Add Recruitment',
            access_roles: ['company', 'individual'],
            link: '/recruitment/jobs/add'
          },
          {
            name: 'View Recruitments',
            access_roles: ['company', 'individual'],
            link: '/recruitment/jobs/all'
          },
          {
            name: 'View Applicants',
            access_roles: ['company', 'individual'],
            link: '/recruitment/applicants/all'
          },
        ],
      }
    ]
  },
];

export default menuItems;
