import { createSlice } from '@reduxjs/toolkit';
import { ConfigState } from 'src/models/store';
import { asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { agencyOfferedServices, getAllCountries, getKYCMatchingTitles, getKYCSectionTitles, getSystemConfig } from './configService';

const initialState: ConfigState = {
  message: '',
  services: [],
  countries: [],
  kyc_section_titles: [],
  status: null,
  agent_maintenance_mode: false,
  accepted_documents: [],
  kyc_match_titles: [],
  dollar_rate: 0,
  visa_types: [
    {
      visa_name: 'Initial visa application',
      id: 1,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Visa renewal',
      id: 2,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Permanent residency',
      id: 3,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Citizenship',
      id: 4,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Study permit extension',
      id: 5,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Work permit',
      id: 6,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Study permit from abroad',
      id: 7,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
    {
      visa_name: 'Study permit from within the country',
      id: 8,
      is_active: true,
      createdAt: '',
      updatedAt: ''
    },
  ]
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    clearConfigState: (state) => {
      state.status = null;
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(agencyOfferedServices.pending, asyncIsPending)
    builder.addCase(agencyOfferedServices.rejected, asyncIsRejected)
    builder.addCase(agencyOfferedServices.fulfilled, (state, action) => {
      state.status = null;
      state.services = action.payload;
    })
    builder.addCase(getAllCountries.pending, asyncIsPending)
    builder.addCase(getAllCountries.rejected, asyncIsRejected)
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.status = null;
      state.countries = action.payload;
    })
    builder.addCase(getSystemConfig.pending, asyncIsPending)
    builder.addCase(getSystemConfig.rejected, asyncIsRejected)
    builder.addCase(getSystemConfig.fulfilled, (state, action) => {
      state.status = null;
      state.dollar_rate = action.payload.dollar_rate;
      state.accepted_documents = action.payload.accepted_documents;
      state.agent_maintenance_mode = action.payload.agent_maintenance_mode;
    })
    builder.addCase(getKYCMatchingTitles.pending, asyncIsPending)
    builder.addCase(getKYCMatchingTitles.rejected, asyncIsRejected)
    builder.addCase(getKYCMatchingTitles.fulfilled, (state, action) => {
      state.status = null;
      state.kyc_match_titles = action.payload;
    })
    builder.addCase(getKYCSectionTitles.pending, asyncIsPending)
    builder.addCase(getKYCSectionTitles.rejected, asyncIsRejected)
    builder.addCase(getKYCSectionTitles.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.kyc_section_titles = action.payload;
    })
  }
});

export const { clearConfigState } = configSlice.actions;
export default configSlice.reducer;