import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { FormikErrors } from 'formik'
import DropZone from 'src/components/DropZone'
import { MaritalStatus } from 'src/models/agent'

interface IndFormTwoProps {
  agentInfo: IndFormTwoInfo,
  errors: FormikErrors<IndFormTwoInfo>,
  onChange: any
  setAgentInfo: (field: keyof IndFormTwoInfo, value: any, shouldValidate?: boolean | undefined) => void
}

export interface IndFormTwoInfo {
  national_id_card_type: string
  national_id_card_number: string
  marital_status: MaritalStatus,
  marriage_certificate: File
  birth_certificate: File
  national_id_card_back: File
  national_id_card_front: File
  passport_bio_page: File
};

const IndFormTwo: React.FC<IndFormTwoProps> = ({ agentInfo, setAgentInfo, errors, onChange }) => {
  return (
    <Grid container spacing={2} item lg={12} alignItems={'center'} justifyContent="center">
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">ID Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="national_id_card_type"
            onChange={onChange}
            error={Boolean(errors.national_id_card_type)}
            value={agentInfo?.national_id_card_type}
          >
            <MenuItem value="National ID">National ID</MenuItem>
            <MenuItem value="Voters ID">Voters ID</MenuItem>
            <MenuItem value="Drivers license">Drivers license</MenuItem>
            <MenuItem value="ECOWAS Identity Card">ECOWAS Identity Card</MenuItem>
          </Select>
          {Boolean(errors.national_id_card_type) && <Typography color={'red'} fontSize={12}>{errors.national_id_card_type}</Typography>}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            label="ID number"
            onChange={onChange}
            name="national_id_card_number"
            type={'text'}
            error={Boolean(errors.national_id_card_number)}
            helperText={
              <Typography fontSize={12}>{errors.national_id_card_number}</Typography>
            }
            value={agentInfo?.national_id_card_number}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Marital Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="marital_status"
            onChange={onChange}
            label={('Marital Status')}
            error={Boolean(errors && errors.marital_status)}
            value={agentInfo?.marital_status}
          >
            <MenuItem key="single" value="single">
              Single
            </MenuItem>
            <MenuItem key="married" value="married">
              Married
            </MenuItem>
            <MenuItem key="seperated" value="seperated">
              Seperated
            </MenuItem>
            <MenuItem key="divorced" value="divorced">
              Divorced
            </MenuItem>
            <MenuItem key="widowed" value="widowed">
              Widowed
            </MenuItem>
          </Select>
          {Boolean(errors.marital_status) && <Typography color={'red'} fontSize={12}>{errors.marital_status}</Typography>}
        </FormControl>
      </Grid>
      {agentInfo?.marital_status === 'married' &&
        <>
          <br />
          <Grid item xs={12}>
            <DropZone
              placeholder='Upload Marriage Certificate'
              error={errors.marriage_certificate as string}
              onFileDrop={(file) => {
                setAgentInfo('marriage_certificate', file[0])
              }}
              file={agentInfo.marriage_certificate}
              acceptedExts={{
                '*': ['*']
              }}
            />
          </Grid>
        </>
      }
      <Grid item xs={12}>
        <DropZone
          file={agentInfo.birth_certificate}
          placeholder='Upload Birth Certificate'
          error={errors.birth_certificate as string}
          onFileDrop={(file) => {
            setAgentInfo('birth_certificate', file[0])
          }}
          acceptedExts={{
            '*': ['*']
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DropZone
          file={agentInfo.national_id_card_back}
          placeholder='National ID Card Back'
          error={errors.national_id_card_back as string}
          onFileDrop={(file) => {
            setAgentInfo('national_id_card_back', file[0])
          }}
          acceptedExts={{
            '*': ['*']
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DropZone
          file={agentInfo.national_id_card_front}
          placeholder='National ID Card Front'
          error={errors.national_id_card_front as string}
          onFileDrop={(file) => {
            setAgentInfo('national_id_card_front', file[0])
          }}
          acceptedExts={{
            '*': ['*']
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DropZone
          file={agentInfo.passport_bio_page}
          placeholder='Upload Passport Bio Page'
          error={errors.passport_bio_page as string}
          onFileDrop={(file) => {
            // console.log('Drop Passport Bio Page', file[0]);
            setAgentInfo('passport_bio_page', file[0])
          }}
          acceptedExts={{
            '*': ['*']
          }}
        />
      </Grid>
    </Grid>
  )
}

export default IndFormTwo
