import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Client, ClientMessage } from "src/models/client";
import axios from '../axios';

export const addClient = createAsyncThunk(
  'clients/addClient',
  async (client: Client, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post('/agent/clients', client);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllClients = createAsyncThunk(
  'clients/getAllClients',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/agent/clients');
      return fulfillWithValue(data.clients);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getClientByUuid = createAsyncThunk(
  'clients/getClientByUuid',
  async (client_uuid: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/clients/${client_uuid}`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getClientFiles = createAsyncThunk(
  'clients/getClientFiles',
  async (client_uuid: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/clients/${client_uuid}/files`);
      return fulfillWithValue(data.files);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const submitClientDocsForReview = createAsyncThunk(
  'clients/submitClientDocsForReview',
  async ({ client_uuid, title_ids_shared }: { client_uuid: string, title_ids_shared: number[] }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.put(`/agent/clients/${client_uuid}/doc-review`, { title_ids_shared });
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getClientQuestionnaire = createAsyncThunk(
  'clients/getClientQuestionnaire',
  async (client_uuid: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/clients/${client_uuid}/questionnaire`);
      return fulfillWithValue(data.questionnaire);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getClientMessages = createAsyncThunk(
  'clients/getClientMessages',
  async (client_uuid: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/clients/${client_uuid}/messages`);
      return fulfillWithValue(data.messages);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendNewMessage = createAsyncThunk(
  'clients/sendNewMessage',
  async (newMessage: ClientMessage, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/agent/clients/${newMessage.client_uuid}/messages`, newMessage);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const saveClientDetails = createAsyncThunk(
  'clients/saveClientDetails',
  async (details: Client, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/agent/clients/${details?.uuid}/details`, details);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);



export const getKYCSectionTitles = createAsyncThunk(
  'clients/getKYCSectionTitles',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get('/agent/clients/kyc/section-titles');
      return fulfillWithValue(data.titles);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendGeneratedLink = createAsyncThunk(
  'clients/sendGeneratedLink',
  async (client_uuid: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/agent/clients/${client_uuid}/link`);
      return fulfillWithValue(data);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);