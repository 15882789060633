import { configureStore } from '@reduxjs/toolkit'
import { reducer as notificationsReducer } from 'reapop'
import applicationSlice from './applications/applicationSlice'
import authSlice from './auth/authSlice'
import clientsSlice from './clients/clientSlice'
import configSlice from './configurations/configSlice'
import dashboardSlice from './general/dashboard/dashboardSlice'
import notificationSlice from './notifications/notificationSlice'
import recruitmentSlice from './recruitment/recruitmentSlice'

export const store = configureStore({
  reducer: {
    notifications: notificationsReducer(),
    auth: authSlice,
    recruitment: recruitmentSlice,
    config: configSlice,
    dashboard: dashboardSlice,
    clients: clientsSlice,
    applications: applicationSlice,
    app_notification: notificationSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch