/* eslint-disable no-use-before-define */
import { alpha, Box, List, ListSubheader, styled } from '@mui/material';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { Agent } from 'src/models/agent';
import { getAgent } from 'src/store/auth/authSlice';
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from './items';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          &.active {
            background-color: ${alpha(theme.colors.alpha.white[100], 100)};
            color: ${theme.colors.primary.dark};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.primary.dark};
            }

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.dark};
            }
          }

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active {
            background-color: ${alpha(theme.colors.alpha.white[100], 100)};
            color: ${theme.colors.primary.dark};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.primary.dark};
            }

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.dark};
            }
          }

          &.active:hover {
            background-color: ${theme.colors.alpha.black[30]};
            color: ${theme.colors.alpha.trueWhite[70]};
          }

          &:hover {
            background-color: ${theme.colors.alpha.black[70]};
            color: ${theme.colors.alpha.trueWhite};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.primary.dark};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const reduceChildRoutes = ({
  ev,
  path,
  agent,
  item
}: {
  ev: JSX.Element[];
  path: string;
  agent: Agent | null
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
      {
        path: item.link,
        end: true
      },
      path
    )
    : false;

  if (item.access_roles.includes(agent?.agent_category!)) {
    if (item.items) {
      const partialMatch = item.link
        ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
        : false;

      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
        >
          {renderSidebarMenuItems({
            path,
            agent,
            items: item.items
          })}
        </SidebarMenuItem>
      );
    } else {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          icon={item.icon}
        />
      );
    }
  }

  return ev;
};

const renderSidebarMenuItems = ({
  items,
  agent,
  path
}: {
  items: any[];
  agent: Agent | null
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path, agent }), [])}
    </List>
  </SubMenuWrapper>
);



function SidebarMenu() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { agent } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAgent());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {menuItems.map((section) => {
        const included = section.items.some((item) => item.access_roles.includes(agent?.agent_category!));
        if (!included) {
          return <></>;
        }
        return (
          <MenuWrapper key={section.heading}>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {(section.heading)}
                </ListSubheader>
              }
            >
              {renderSidebarMenuItems({
                items: section.items,
                agent: agent,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        )
      })}
    </>
  );
}

export default SidebarMenu;
