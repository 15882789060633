import { createSlice } from '@reduxjs/toolkit';
import { Client } from 'src/models/client';
import { ClientsState } from 'src/models/store';
import { asyncIsFulfilled, asyncIsPending, asyncIsRejected } from '../asyncConfig';
import { addClient, getAllClients, getClientByUuid, getClientFiles, getClientMessages, getClientQuestionnaire, getKYCSectionTitles, saveClientDetails, sendGeneratedLink, sendNewMessage, submitClientDocsForReview } from './clientServices';

const initialState: ClientsState = {
  message: '',
  status: null,
  kyc_section_titles: [],
  clients: [],
  files: [],
  messages: [],
  kyc_questionnaire: [],
  title_ids_shared: [],
  admin_reviewed_kyc_questionnaire: []
}

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    clearClientState: (state) => {
      state.status = null;
      state.message = '';
      state.clients = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addClient.pending, asyncIsPending)
    builder.addCase(addClient.fulfilled, asyncIsFulfilled)
    builder.addCase(addClient.rejected, asyncIsRejected)
    builder.addCase(submitClientDocsForReview.pending, asyncIsPending)
    builder.addCase(submitClientDocsForReview.fulfilled, asyncIsFulfilled)
    builder.addCase(submitClientDocsForReview.rejected, asyncIsRejected)
    builder.addCase(saveClientDetails.pending, asyncIsPending)
    builder.addCase(saveClientDetails.fulfilled, asyncIsFulfilled)
    builder.addCase(saveClientDetails.rejected, asyncIsRejected)
    builder.addCase(sendGeneratedLink.pending, asyncIsPending)
    builder.addCase(sendGeneratedLink.fulfilled, asyncIsFulfilled)
    builder.addCase(sendGeneratedLink.rejected, asyncIsRejected)
    builder.addCase(sendNewMessage.pending, asyncIsPending)
    builder.addCase(sendNewMessage.fulfilled, asyncIsFulfilled)
    builder.addCase(sendNewMessage.rejected, asyncIsRejected)
    builder.addCase(getKYCSectionTitles.pending, asyncIsPending)
    builder.addCase(getKYCSectionTitles.rejected, asyncIsRejected)
    builder.addCase(getKYCSectionTitles.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.kyc_section_titles = action.payload;
    })
    builder.addCase(getAllClients.pending, asyncIsPending)
    builder.addCase(getAllClients.rejected, asyncIsRejected)
    builder.addCase(getAllClients.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.clients = action.payload;
    })
    builder.addCase(getClientByUuid.pending, asyncIsPending)
    builder.addCase(getClientByUuid.rejected, asyncIsRejected)
    builder.addCase(getClientByUuid.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      const client = action.payload.client as Client;
      state.status = null;
      state.clients = [{
        ...client,
        is_kyc_submitted: action.payload.is_kyc_submitted,
        is_service_type_saved: client.service_type !== null
      }];
    })
    builder.addCase(getClientMessages.pending, asyncIsPending)
    builder.addCase(getClientMessages.rejected, asyncIsRejected)
    builder.addCase(getClientMessages.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.messages = action.payload;
    })
    builder.addCase(getClientFiles.pending, asyncIsPending)
    builder.addCase(getClientFiles.rejected, asyncIsRejected)
    builder.addCase(getClientFiles.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.files = action.payload;
    })
    builder.addCase(getClientQuestionnaire.pending, asyncIsPending)
    builder.addCase(getClientQuestionnaire.rejected, asyncIsRejected)
    builder.addCase(getClientQuestionnaire.fulfilled, (state, action) => {
      console.log('FULFILLED:: ', action.payload);
      state.status = null;
      state.title_ids_shared = action.payload.title_ids_shared
      state.kyc_questionnaire = action.payload.kyc;
      state.admin_reviewed_kyc_questionnaire = action.payload.admin_reviewed_kyc;
    })
  }
});

export const { clearClientState } = clientsSlice.actions;
export default clientsSlice.reducer;