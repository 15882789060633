import { Box, Grid, TextField, Typography } from '@mui/material'
import { FormikErrors } from 'formik'
import DropZone from 'src/components/DropZone'
import { BaseInfo } from '../SignupPage'

interface CollectorFormOneProps {
  agentInfo: CollectorFormOneInfo,
  onChange: any,
  errors: FormikErrors<CollectorFormOneInfo>,
  setAgentInfo: (field: keyof CollectorFormOneInfo, value: any, shouldValidate?: boolean | undefined) => void
}

export interface CollectorFormOneInfo extends BaseInfo {
  organization_logo: File,
  organization_name: string
  organization_license_number: string
  alias: string
  brand_color: string,
}

const CollectorFormOne: React.FC<CollectorFormOneProps> = ({ agentInfo, setAgentInfo, errors, onChange }) => {
  const cleanText = (text: string) => {
    let cleanedText = text.toLowerCase();
    cleanedText = cleanedText.replace(/[^a-z0-9\s-]/g, '');
    return cleanedText;
  }

  return (
    <Grid container spacing={2} item lg={12} mt={1} alignItems={'center'} justifyContent="center">
      <>
        <Grid item xs={12}>
          <DropZone
            file={agentInfo.organization_logo}
            error={errors.organization_logo as string}
            placeholder='Upload Organization Logo'
            onFileDrop={(file) => {
              setAgentInfo('organization_logo', file[0])
            }}
            acceptedExts={{
              'image/png': ['.png'],
              'image/jpeg': ['.jpg']
            }}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <TextField
            label="Organization Name"
            fullWidth
            onChange={onChange}
            name="organization_name"
            type={'text'}
            error={Boolean(errors && errors.organization_name)}
            helperText={
              <Typography fontSize={12}>{errors.organization_name}</Typography>
            }
            value={agentInfo?.organization_name}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box borderRadius={0.6} p={1.5} border={`1px solid ${(errors.brand_color?.length ?? 0) > 0 ? 'red' : '#e5e5f7'} `} display={'flex'} alignItems={'center'}>
            <Typography
              variant='h5'
              color={'darkslategrey'} mr={4}>Select Brand Color</Typography>
            <input
              value={agentInfo.brand_color || '#ffffff'}
              onChange={(e) => {
                setAgentInfo('brand_color', e.target.value || 'transparent')
              }}
              type="color"
            />
          </Box>
          <Typography fontSize={12} color={'red'}>{errors.brand_color}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Alias"
            fullWidth
            onChange={(e) => {
              setAgentInfo('alias', cleanText(e.target.value))
            }}
            name="alias"
            error={Boolean(errors.alias)}
            helperText={
              <Typography fontSize={12}>{errors.alias}</Typography>
            }
            value={agentInfo?.alias}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Organization Email Address"
            fullWidth
            onChange={onChange}
            name="email_address"
            error={Boolean(errors.email_address)}
            helperText={
              <Typography fontSize={12}>{errors.email_address}</Typography>
            }
            value={agentInfo?.email_address}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Organization Licence Number"
            fullWidth
            onChange={onChange}
            name="organization_license_number"
            error={Boolean(errors.organization_license_number)}
            helperText={
              <Typography fontSize={12}>{errors.organization_license_number}</Typography>
            }
            value={agentInfo?.organization_license_number}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Password"
            fullWidth
            onChange={onChange}
            name="password"
            type={'password'}
            error={Boolean(errors.password)}
            helperText={
              <Typography fontSize={12}>{errors.password}</Typography>
            }
            value={agentInfo?.password}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Confirm Password"
            fullWidth
            onChange={onChange}
            name="confirm_password"
            type={'password'}
            error={Boolean(errors.confirm_password)}
            helperText={
              <Typography fontSize={12}>{errors.confirm_password}</Typography>
            }
            value={agentInfo?.confirm_password}
            variant="outlined"
          />
        </Grid>
      </>
    </Grid>
  )
}

export default CollectorFormOne
