import React, { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import NotificationsSystem, { dismissNotification, setUpNotifications, wyboTheme } from 'reapop';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import ThemeProvider from './theme/ThemeProvider';
import { setupAxiosResponseInterceptors } from './store/axios';

const App: React.FC = () => {
  const content = useRoutes(router);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notifications = useAppSelector((state) => state.notifications)
  const state = useAppSelector((state) => state)

  console.log('STATE: ', state);
  
  useEffect(() => {
    setupAxiosResponseInterceptors(dispatch, navigate)
    setUpNotifications({
      defaultProps: {
        position: 'top-center',
        dismissible: true,
        dismissAfter: 3600
      }
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <NotificationsSystem
        notifications={notifications}
        dismissNotification={(id) => dispatch(dismissNotification(id))}
        theme={wyboTheme}
      />
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        {content}
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
