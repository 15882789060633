import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import SidebarLayout from './layouts/SidebarLayout';
import Auth from './pages/auth';


const Loader = (Component: React.FC) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Auth
// const Auth = Loader(
//   lazy(() => import('src/pages/auth/index') as <Auth />)
// );


// General
const Dashboard = Loader(
  lazy(() => import('src/pages/general/dashboard'))
);
const ClientsPage = Loader(
  lazy(() => import('src/pages/clients'))
);
const ClientInformation = Loader(
  lazy(() => import('src/pages/clients/ClientInformation'))
);


// Recruitment
const AddJob = Loader(
  lazy(() => import('src/pages/recruitment/manage_jobs/AddJob'))
);
const ViewJobs = Loader(
  lazy(() => import('src/pages/recruitment/manage_jobs/ViewJobs'))
);
const ViewApplicants = Loader(
  lazy(() => import('src/pages/recruitment/manage_jobs/ViewApplicants'))
);

// Meetings
const AgentProfile = Loader(
  lazy(() => import('src/pages/profile'))
);

//Applications
const RequestedApplication = Loader(
  lazy(() => import('src/pages/applications'))
);

// Components

// Status
const Status404 = Loader(
  lazy(() => import('src/pages/status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/pages/status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/pages/status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/pages/status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="login" replace />
      },
      {
        path: 'login',
        element: <Auth type={'login'} />
      },
      {
        path: 'forget-password',
        element: <Auth type={'forget-password'} />
      },
      {
        path: 'reset-password',
        element: <Auth type={'reset-password'} />
      },
      {
        path: 'create-account',
        element: <Auth type={'signup'} />
      },
      {
        path: 'profile',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <AgentProfile />
          },
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'clients',
    element: <SidebarLayout />,
    children: [
      {
        index: true,
        element: <ClientsPage />
      },
      {
        path: 'info/:uuid',
        element: <ClientInformation />
      }
    ]
  },
  {
    path: 'general/dashboard',
    element: <SidebarLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />
      },
    ]
  },
  {
    path: 'recruitment',
    element: <SidebarLayout />,
    children: [
      {
        path: 'applicants/all',
        element: <ViewApplicants />
      },
      {
        path: 'jobs',
        children: [
          {
            path: 'all',
            element: <ViewJobs />
          },
          {
            path: 'add',
            element: <AddJob />
          },
          {
            path: 'edit/:job_id',
            element: <AddJob />
          },
        ]
      }
    ]
  },
  {
    path: 'applications/',
    element: <SidebarLayout />,
    children: [
      {
        path: 'all',
        element: <RequestedApplication />
      }
    ]
  },
];

export default routes;
